import React, {useEffect} from "react";
import portfolioImage from '../assets/portfolio_pic.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
    useEffect(() => {
        AOS.init({
          duration: 1750, // Animation duration
          easing: 'ease-out', 
          once: true, 
        });
      }, []);

    return (
        <div className="flex flex-col md:flex-row justify-between items-center  pb-32 pt-0 " data-aos="fade-up">
            {/* Image Section */}
            <div className="w-full md:w-5/12 flex justify-start pl-20 items-center mb-8 md:mb-0"> 
                <img src={portfolioImage} alt="Portfolio" className="rounded-full h-72 w-72 object-cover" />
            </div>

            {/* Text Section */}
            <div className="w-full md:w-7/12 text-white text-center md:text-left max-w-sm md:max-w-none mx-auto md:mx-0 px-4"> 
                <h2 className="text-3xl font-bold mb-4">About Me</h2>
                <p className="text-lg leading-relaxed">
                    Hello, I am Kaleb, I am currently in my 4th year at the University of Florida. I am about to finish my bachelors
                    in Computer Science with a passion for many different disciplines within the field. When I first started college I was 
                    fully convinced that I wanted to be a software developer, but since then I have learned that there is so much more to CS than just
                    coding. I have gained extensive experience with cloud technologies, learned alot about cybersecurity, and also have tried playing with
                    hardware. Every one of these things has taught me a new skill and I would consider myself to be much more well rounded because of it. 
                </p>
            </div>
        </div>
    );
}

export default About;
