import React, { useState } from 'react';
import logo from '../assets/Logo.png'; 
import instagram from '../assets/instagram-icon.png'
import github from '../assets/github-icon.png'
import linkedin from '../assets/linkedIn-icon.png'
import resume from '../assets/resume_logo.png'
import resumePDF from '../assets/Resume.pdf'
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-black text-white p-4">
      <div className="flex justify-between items-center">
        <img src={logo} alt="Logo" className="h-24 w-24" />

        {/* Hamburger Icon */}
        <button 
          className="block lg:hidden focus:outline-none" 
          onClick={toggleMenu}
        >
          <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
          </svg>
        </button>

        {/* Navigation Links */}
        <nav className={`lg:flex lg:items-center lg:space-x-11 text-2xl ${isOpen ? 'block' : 'hidden'} lg:block`}>
          <ul className="flex flex-col lg:ml-20 lg:pl-10 lg:flex-row space-y-6 lg:space-y-0 lg:space-x-11">
            <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? 'hover-item active' : 'hover-item')}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/portfolio" className={({ isActive }) => (isActive ? 'hover-item active' : 'hover-item')}>
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className={({ isActive }) => (isActive ? 'hover-item active' : 'hover-item')}>
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>

        {/* Social Icons */}
        <div className="hidden lg:flex space-x-4">
          <a href={resumePDF} target="_blank" rel="noopener noreferrer">
            <img src={resume} alt="Resume" className="h-9 w-9" />
          </a>
          <a href="https://www.instagram.com/kalebjellington/" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="Instagram" className="h-10 w-auto" />
          </a>
          <a href="https://github.com/KalebE36" target="_blank" rel="noopener noreferrer">
            <img src={github} alt="Github" className="h-9 w-auto" />
          </a>
          <a href="https://www.linkedin.com/in/kaleb-ellington/" target="_blank" rel="noopener noreferrer">
            <img src={linkedin} alt="LinkedIn" className="h-11 w-auto" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
